import React, { FunctionComponent } from 'react'
import ModalBackground from './modalBackground';

const InfoModal: FunctionComponent<{ title?: String,showModal:boolean,onClose?:Function,hideCloseBtn?:boolean }> = ({ title,showModal,children,onClose,hideCloseBtn }) => {
    return (<>{showModal?
        <ModalBackground>
             <div style={{boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} className="relative bg-white rounded-lg  flex flex-col w-72 sm:w-3/4 max-w-2xl mt-16  opacity-100 pb-5">
                <span className="font-bold text-gray-800 text-lg mx-5 mt-5 w-3/4">{title}</span>
                {title?<span className="border-gray-300 border-b w-full"></span>:null}
                {!hideCloseBtn?<button className="absolute bg-transparent text-2xl text-bytestrone-blue font-semibold leading-none right-0 top-0 mt-2 mr-3 p-1 outline-none focus:outline-none rounded-full hover:shadow-lg" onClick={()=>{
                    if(onClose){
                        onClose();
                    }
                }}>
                    <span>×</span>
                </button>:null}
                <div className="w-full overflow-auto">{children}</div>
            </div>
        </ModalBackground>
    :null}</>)
}

export default InfoModal;