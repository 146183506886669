//import { graphql, useStaticQuery } from 'gatsby'
import React, { FunctionComponent } from 'react'
//import CarousilCard from '../components/shared/cards/carouselCard';
//import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
//import AutoCardSlider,{SliderCard} from '../components/shared/autoCardSlider';
//import Hexagon from '../components/shared/hexagon';
//import hexImage from "../images/logo_filtered.svg"
import Purpose from '../components/purpose';
import Vision from '../components/vision';
import Values from '../components/values';
import OurLeadershipTeam from '../components/about/ourLeadership';
import OurExecutiveLeadershipTeam from '../components/ourExecutiveLeadership';
const About:FunctionComponent =()=> {
    
  return (
    <>
      <Purpose/>
      <Vision/>
      <Values/>
      <OurExecutiveLeadershipTeam/>
      <OurLeadershipTeam/>
    </>
  )

}


export default About;