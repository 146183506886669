import React, { FunctionComponent } from 'react'

const ModalBackground: FunctionComponent = ({children}) => {
    return (
        <div className="absolute z-50 bottom-0 top-0 left-0 right-0 bg-opacity-60 bg-transparent flex flex-col items-center justify-center">
            {children}
        </div>
    )
}

export default ModalBackground;