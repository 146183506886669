import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const Values: FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue relative  px-6 ">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <div id="values" className="flex flex-col w-full md:h-full pt-10 md:pt-20 space-y-20 md:space-y-40 pb-10">
                <div >
                    <Text className="text-center text-white lg:text-right whitespace-nowrap mb-5" textType="headerL">Our Values</Text>
                </div>
                
                <div className="md:mx-28 mx-4 md:mt-4 mt-2 hidden md:block items-center">
                        <StaticImage  src="../images/our-values.png" alt="Our Values" placeholder="blurred" quality={100} />                    
                </div>
                <div className="sm:mt-2 md:hidden flex justify-center items-center">
                        <StaticImage  src="../images/our-values-mobile.png" alt="Our Values" placeholder="blurred" quality={100} />                    
                </div>
                <div >
                      <p className="text-xl md:text-4xl text-white text-center lg:pl-28">Our values define what we stand for, and how we behave.​</p>
                </div>
            </div>
        </Section>
    )
}

export default Values;