import React, { FunctionComponent, Fragment, useCallback } from 'react'
import { useState } from 'react'
import TeamCard from '../shared/cards/teamCard'
import InfoModal from '../shared/modal/infoModal'
import Section from '../shared/section/sectionWrapper'
import { graphql,useStaticQuery } from 'gatsby'
import {getImage, GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image"
import Text from '../shared/text'

 interface Team {
    firstname:string,
    lastname:string,
    isLeft:boolean,
    designation:string,
    description:string,
    image:IGatsbyImageData
 }

 const LeadershipQuery =graphql `
    query LeadershipQuery {
      allStrapiLeaderships(sort: {order: ASC, fields: sequence}) {
        edges {
          node {
            id
            sequence
            title
            image {
              localFile {
                childImageSharp {
                 gatsbyImageData(
                            width:400
                            blurredOptions:{width:100}
                            placeholder:BLURRED
                            transformOptions: {cropFocus:CENTER}
                          )
                }
              }
            }
            isLeft
            lastname
            strapiId
            firstname
            designation
            description
          }
        }
      }
    }
 `;

 


 const OurLeadershipTeam:FunctionComponent =()=> {

    const [details,showDetails] = useState(false);
    const [currentMember,setCurrentMember] = useState<Team>();
    const {allStrapiLeaderships} = useStaticQuery(LeadershipQuery);
    const showTeamMemberDetails = useCallback((data:Team)=> {
        setCurrentMember(data)
        showDetails(true);
    },[])

    
    return (
  
 <Section  className="bg-bytestrone-blue p-5 h-full space-y-10 relative pt-10 md:pt-20">
            <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL"> Leadership Team</Text>
            <div  id="leadership"  className="flex flex-row flex-wrap ml-5">
                {allStrapiLeaderships.edges.map((item,index)=> {
                    const image = getImage(item.node.image.localFile.childImageSharp.gatsbyImageData);
                    const reminder = (item.node.sequence) % 2;
                    return  reminder ? 
                    <div className="w-full md:w-1/2 md:-mr-4 md:-mb-10 xl:-mr-7 xl:-mb-1 " id={`left${index}`} key={`${index}`}>
                              <div className="md:m-5 xl:mx-10 xl:my-0  md:text-center">
                                  {image?<TeamCard  id={item.node.id} firstname={item.node.firstname} lastname={item.node.lastname} textColor="text-white" isLeft={item.node.isLeft} designation={item.node.designation} description={item.node.description} image={image} title={item.node.title} onClick = {showTeamMemberDetails}  />:null}
                              </div> 
                            </div>
                              :
                             <div className="w-full md:w-1/2 md:-ml-5  md:mt-24 md:-mb-24 xl:mt-20 xl:-ml-8 xl:-mb-16 " id={`right${index}`} key={`${index}`} >
                              <div className="md:m-5  xl:mx-10 xl:my-0  md:text-center"  id={`right-Inside${index}`} >
                                  {image?<TeamCard  id={item.node.id} firstname={item.node.firstname} lastname={item.node.lastname}  textColor="text-white" isLeft={item.node.isLeft} designation={item.node.designation} description={item.node.description} image={image} title={item.node.title} onClick = {showTeamMemberDetails}  />:null}
                              </div> 
                            </div>
                              

                })}
            </div>
            
            <InfoModal  showModal={details} onClose={()=>{showDetails(false);setCurrentMember(undefined);}}>
               
                  <div >
                      <div className="flex flex-row items-center justify-center">{currentMember?<GatsbyImage image={currentMember.image} alt={currentMember?.firstname} className="border-none w-40"/>:null}</div>
                      <div className="mx-auto text-lg text-gray-500 font-bold text-center" >{currentMember?.firstname} {currentMember?.lastname}</div>
                      <div className="mx-auto text-lg text-black font-bold text-center" >{currentMember?.designation}</div>
                  </div>
                
                  <div style={{whiteSpace:"pre-wrap"}} className="text-gray-500 text-sm text-left max-h-52 md:max-h-96 p-5 md:p-10 pt-5 overflow-auto">
                    {currentMember?.description}
                  </div>
               
               
            </InfoModal>
            <div></div>
            <div></div>
            <div></div>
        </Section>
   
       
    )
}



export default OurLeadershipTeam;