import React, { FunctionComponent } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'


// const TeamCard: FunctionComponent<{ id?:string, name: string, designation:String,onClick?:Function,image:IGatsbyImageData,description?:string }> = ({id, name, designation,image,description,onClick }) => {
//     return (
//         <div className="rounded-xl  m-14 py-10 bg-gray-0 w-80">
//             <div className="rounded-full mx-auto w-60 h-60 bg-bytestrone-purple transform  hover:animate-pulse">
//                 <button className="cursor-pointer" onClick={(e)=>{
//                     e.preventDefault();
//                     e.stopPropagation();
//                     if(onClick) {
//                         onClick({
//                             id:id,
//                             name:name,
//                             image:image,
//                             description:description,
//                             designation:designation
//                         });
//                     }
//                 }}>
//                     {image?<GatsbyImage image={image} alt={name} className="shadow-lg rounded-full mx-auto w-60 h-60  align-middle border-none"/>:null}
//                 </button>
//             </div>
//             <div className="mx-auto text-xl text-gray-500 font-bold text-center" >{name}</div>
//             <div className="mx-auto text-xl text-black font-bold text-center" >{designation}</div>
//         </div>
//     )
// }

const TeamCard: FunctionComponent<{ id?: string, firstname: string, lastname: string,  textColor:string, designation: string, title: string, onClick?: Function, image: IGatsbyImageData, description?: string, isLeft?: boolean, }> = ({ id, firstname, lastname,  textColor, designation, title, image, description, isLeft, onClick }) => {

    return (
        <div className="flex flex-col w-full  relative">

            {!isLeft ? <>
                <div className="z-30 w-32 h-32 md:w-40 md:h-40 flex flex-col justify-end self-end mb-0">
                    <button className="cursor-pointer focus:outline-none w-full" style={{ fontSize: '0px' }} onClick={(e) => {
                        onProfileClick(e, onClick, id, firstname, lastname, description, title, image);
                    }}>
                        {image ? <GatsbyImage image={image} alt={firstname} className="border-none transform " /> : null}
                    </button>
                </div>
                <div className="absolute right-10 bottom-0 border-solid h-20" style={{ width: "calc(100% - 40px)" }}>
                    <div className="border-2 border-bytestrone-green cursor-pointer rounded-lg h-full w-full flex flex-col justify-center pl-2 pr-24 md:pr-32" onClick={(e) => {
                        onProfileClick(e, onClick, id, firstname, lastname, description, title, image);
                    }}>
                        <ProfileDetails firstname={firstname} lastname={lastname} designation={designation}  textColor={textColor} />
                    </div>
                </div></>
                : <>
                    <div className="z-30  w-32 h-32 md:w-40 md:h-40 flex flex-col justify-end self-start mb-0">
                        <button className="cursor-pointer focus:outline-none" style={{ fontSize: '0px' }} onClick={(e) => {
                            onProfileClick(e, onClick, id, firstname, lastname, description, title, image);
                        }}>
                            {image ? <GatsbyImage  image={image} alt={firstname} className="border-none transform " /> : null}
                        </button>
                    </div>
                    <div className="absolute left-10  bottom-0 border-solid h-20" style={{ width: "calc(100% - 40px)" }}>
                        <div className="border-2 border-bytestrone-green cursor-pointer rounded-lg h-full w-full flex flex-col justify-center pl-24 md:pl-32 float-right" onClick={(e) => {
                            onProfileClick(e, onClick, id, firstname, lastname, description, title, image);
                        }}>
                            <ProfileDetails firstname={firstname} lastname={lastname} designation={designation} textColor={textColor} />
                        </div>
                    </div></>}
            <button style={{ bottom: "-14px", left: "50%", right: "50%" }} className="z-20 h-4 w-10 rounded-b-md bg-bytestrone-green m-auto flex flex-row items-center justify-evenly absolute cursor-pointer focus:outline-none"
                onClick={(e) => {
                    onProfileClick(e, onClick, id, firstname, lastname, description, title, image);
                }}>
                <div className="bg-white w-1 h-1 m-auto rounded-full"></div>
                <div className="bg-white w-1 h-1 m-auto rounded-full"></div>
                <div className="bg-white w-1 h-1 m-auto rounded-full"></div>
            </button>
        </div>
    )
}

const ProfileDetails: FunctionComponent<{ firstname: string, lastname: string, designation: string,textColor:string }> = ({ firstname, lastname, designation, textColor }) => {
    return (<>
        <span className={`text-xs md:text-sm font-bold ${textColor} `}>{firstname}</span>
        <span className={`text-xs md:text-sm ${textColor}`}>{lastname}</span>
        <span className={`text-xs font-semibold ${textColor} `}>{designation}</span>
    </>)
}

const onProfileClick = (e, onClick, id, firstname, lastname, description, designation, image) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
        onClick({
            id: id,
            firstname: firstname,
            lastname: lastname,
            image: image,
            description: description,
            designation: designation
        });
    }
}

export default TeamCard;