import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
//import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const Purpose: FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue relative  px-6">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <div id="purpose" className="flex flex-col w-full md:h-full pt-20 space-y-20 md:space-y-40 pb-10">
                <div >
                    <Text className="text-center text-white lg:text-right whitespace-nowrap mb-5" textType="headerL">Our Purpose</Text>
                </div>
                
                <div>
                    <p className="text-4xl md:text-9xl text-white font-semibold text-center font-bytestrone-georgia capitalize">Engineer ideas to reality.​</p>
                </div>
                <div >
                      <p className="text-xl md:text-4xl text-white text-center lg:pl-28">  Our purpose articulates why we exist. This ambitious purpose guides us.​</p>
                </div>
            </div>
        </Section>
    )
}

export default Purpose;