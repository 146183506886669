import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
//import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const Vision: FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-white relative  px-6 ">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <div id="vision" className="flex flex-col w-full md:h-full pt-10 md:pt-20 space-y-20 md:space-y-40 pb-10">
                <div >
                    <Text className="text-center text-bytestrone-blue lg:text-left whitespace-nowrap mb-5" textType="headerL">Our Vision</Text>
                </div>
                
                <div>
                    <p className="text-2xl md:text-4xl lg:text-8xl text-bytestrone-blue font-semibold text-center font-bytestrone-georgia capitalize">Become quintessential engineering partners to breakthrough innovators.​</p>
                </div>
                <div >
                   
                      <p className="text-xl md:text-4xl text-bytestrone-blue text-center lg:pl-28">Our vision explains what we aim to achieve.​</p>
                   
                </div>
            </div>
        </Section>
    )
}

export default Vision;